import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const toggleMenu = (open, nav) => {
  const header = document.getElementById('js-header');

  if (open) {
    header.classList.remove('bysh__header--open');
    enableBodyScroll(nav);
  } else {
    header.classList.add('bysh__header--open');
    disableBodyScroll(nav);
  }

  return !open;
};

const initMenu = () => {
  const nav = document.getElementById('js-nav');
  const navToggle = document.getElementById('js-nav-toggle');

  let menuState = false;

  window.addEventListener('click', (e) => {
    const { target } = e;

    if (target === navToggle || !menuState) return false;

    if (!nav.contains(target)) {
      menuState = toggleMenu(menuState);

      return true;
    }
  });

  navToggle.addEventListener('click', () => {
    menuState = toggleMenu(menuState, nav);
  });

  window.addEventListener('resize', () => {
    if (window.innerWidth >= 768) {
      menuState = toggleMenu(true, nav);
    }
  });
};

export default initMenu;
