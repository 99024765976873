//require.context('../images', true);

import 'regenerator-runtime/runtime';
import 'bootstrap';

import 'core-js/stable';
import setViewportHeight from '../src/setViewportHeight';
import initMenu from '../src/initMenu';

document.addEventListener(
  'DOMContentLoaded',
  function () {
    // Initialize Scripts
    setViewportHeight();
    initMenu();
  },
  false,
);
